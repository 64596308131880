import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
} from 'reactstrap';
import { Link } from "gatsby-plugin-modal-routing"
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import navigation from '../data/navigation';
import { Nav, NavItem, NavLink } from 'reactstrap';
import Linkks from './link';
import {
  isAndroid,
  isIOS,
  isMobile,
  isTablet,
} from "react-device-detect";
import useSiteMetadata from '../hooks/use-site-config'



let SocialLink = ({Icon, link}) => (
  <Linkks to={link} className="mr-3">
    <Icon size={15}/>
  </Linkks>
)

const isModal=(link, className, name, modal)=> {
  if (modal==="true") {
    return (
      <NavItem>
        <Link to={link} className={className} asModal >{name}</Link>
      </NavItem>
    );
  } else {
    return (
      <NavItem>
      <NavLink href={link} active className={className}>{name}</NavLink>
    </NavItem>
      );
  }
  } 


let CustomNavItem = ({ link, className, name, asModal }) => (
  isModal(link, className, name, asModal)
)

const Header = (props) => {
  
  const { appleStore, playStore } = useSiteMetadata()
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
  
    <Navbar color="light" light expand="md">
      <Container>
        <Link to="/" className="navbar-brand">
          <span className="nameOne">PORTAL </span>
          <span className="nameTwo">PUNTANO</span>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto">
            {isIOS &&
              <CustomNavItem link={appleStore} name={"INGRESAR"} className={"navBtn font-weight-bold mr-4"} asModal={"false"} />
            }
            {isAndroid &&
              <CustomNavItem link={playStore} name={"INGRESAR"} className={"navBtn font-weight-bold mr-4"} asModal={"false"} />
            }
            {!isAndroid && !isIOS && navigation.map(item => (
              <CustomNavItem link={item.url} name={item.name} className={item.className} asModal={item.asModal} />
            ))}
            <NavItem> <SocialLink Icon={FaFacebookF} link="https://www.facebook.com/sanluisgob/"/> </NavItem>
          <SocialLink Icon={FaTwitter} link="https://twitter.com/SanLuisGob1"/>
          <SocialLink Icon={FaInstagram} link="https://www.instagram.com/gobsanluis_1/"/>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
