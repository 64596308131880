import { useStaticQuery, graphql } from 'gatsby'

const useSiteImages = imageName => {
  const result = useStaticQuery(graphql`
    {
      allFile(filter: {relativePath: {regex: "/images/"}}) {
        edges {
          node {
            name
            childImageSharp {
              fixed {
                base64
                aspectRatio
                width
                height
                src
                srcSet
                srcWebp
                srcSetWebp
                originalName
              }
              fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const items = result.allFile.edges
  const image = items.find(edge => edge.node.name === imageName)
  if (image === undefined) {
    throw new Error(`Unable to find image: ${imageName} (in src/assets/images)`)
  }

  return image.node.childImageSharp
}

export default useSiteImages
