import React from 'react'
import styled from "styled-components"
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import { Container, Row, Col } from 'reactstrap'

const FooterContainer = styled.footer`
position: absolute;
bottom: 0;
height: 2.5rem;    
margin-top: 2rem;
  padding: 3rem 0;
  display: table;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
` 


const Footer = ({ props, className }) => {
  const { siteFooter } = useSiteMetadata()
  const { fluid } = useSiteImages(siteFooter)
  const footerImg = fluid.src
  
  return (
    <FooterContainer style={{ backgroundImage: `url("${footerImg}")` }} className={className}>
     
        <span className="version" >v1.0.5</span>
       
    </FooterContainer>
  )
}

export default Footer
