import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteTitle
          siteCover
          siteFooter
          authorName
          siteDescription
          headerTitle
          headerLinksIcon 
          appleStore
          playStore
          serverUrl
          makeProcedureIcon {
            title
            law
            notary
            imageName
            link
          }
          locationProcedureIcon {
            title
            imageName
            link
          }
          downloadIcon{
            title
            imageName
            link
          }
          rpiLicense{
            link
            imageName
          }
          googleMapsUrl
          procedureCasilleroUrl
        }
      }
    }
  `)
  return result.site.siteMetadata
}

export default useSiteMetadata
